<template>
  <el-checkbox v-model="filter.value">
    {{ $t(`system.${!!filter.value}`) }}
  </el-checkbox>
</template>

<script>
import filtersGeneratorMixin from '../mixin/filtersGeneratorMixin';

export default {
  name: 'fieldCheckbox',
  mixins: [filtersGeneratorMixin],
  beforeMount() {
    this.filter.value = !!this.filter.value;
  },
};
</script>

<style scoped>

</style>
